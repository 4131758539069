
export function getThumbnailUrl(thumbnail_path) {
    return (
        'https://retrocausal-video-thumbnails.s3.amazonaws.com/' +
        thumbnail_path +
        '512.jpg'
    );
}

export const trainingThumbnail = (record, taskName, orgName) => {
    // Format: org-training/Desktop Assembly 3 Extended/Videos/2021-05-0610-48-46.mp4/
    const thumbnail_path = `${orgName}-training/${taskName}/Videos/${record.fileName}/`
    return thumbnail_path
}

export const traceThumbnail = (record, taskId, orgName) => {
    // Format: org-videos/deviceId/410/Processed/2022-06-10 09:23:19.mp4/
    const thumbnail_path = `${orgName}-videos/${record.device_id}/${taskId}/Processed/${record.fileName}/`
    return thumbnail_path
}

export const validationThumbnail = (record, orgName) => {
    // Format: org-videos/validation/Desktop Assembly 3 Extended/Videos/2021-05-0610-44-01.mp4/
    const thumbnail_path = `${orgName}-videos/validation/${record.Task.taskName}/Videos/${record.fileName}/`
    return thumbnail_path
}

export const ergonomicThumbnail = (record, orgName) => {
    // Format: org-videos/ergonomics/Videos/process.mp4/
    const thumbnail_path = `${orgName}-videos/ergonomics/Videos/${record.file_name}/`
    return thumbnail_path
}
