import ApiHelper from './index';

const generateThumbnail = async (
  payload, 
  spinner = false
  ) => {
  return await ApiHelper.post(
    'organization/task_record/generate_thumbnail',
    payload,
    spinner
  );
};

export default {
    generateThumbnail,
};
      